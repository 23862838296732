@use "./vars/colors.scss";
@use "./vars/globalstyles.scss";

button {
    background-color: colors.$blue;
    border: 0;
    padding: 1rem 2rem;
    color: #fff;
    border-radius: globalstyles.$borderRadius;
    cursor: pointer;

    &.accept {
        background-color: colors.$green;
    }

    &.decline {
        background-color: colors.$red;
    }
}

.buttons {
    display: flex;
    flex-direction: row;

    button {
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }
}