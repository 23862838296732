@use "vars/breakpoints";
@use "vars/colors";
@use "vars/fonts";
@use "vars/globalstyles";
@use "./messages";
@use "./inputs";

html, body {
	margin: 0;
	padding: 0;
	color: colors.$fontColor;
	box-sizing: border-box;
	font-family: fonts.$mainFont;
}

body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

* {
	box-sizing: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

.styleless {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

.hidden {
    position: absolute;
    top: -1px;
    left: -1px;
    visibility: hidden;
    display: none;
    opacity: 0;
    z-index: -999;
    width: 0;
    height: 0;
    overflow: hidden;
}

@keyframes spinnyTop {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes spinnyBottom {
    0% {
        transform: translate(-50%, 50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, 50%) rotate(360deg);
    }
}

.no-token {
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-weight: lighter;
        text-transform: uppercase;
    }
}

.App {

    .down-arrow {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        padding: 0;
        border-radius: 99px;
        display: flex;
        flex-direction: row;
        align-items: center;
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        transition: opacity .3s 0s, visibility 0s .3s, z-index 0s .3s;
        background-color: colors.$gray;
        
        &.visible {
            opacity: 1;
            visibility: visible;
            z-index: 99999;
            transition: opacity .3s 0s, visibility 0s 0s, z-index 0s 0s;
        }

        img {
            display: block;
            width: 66.6%;
            height: auto;
        }

        span {
            width: 0;
            height: 0;
            overflow: hidden;
            text-indent: -9999px;
        }
    }

    .wrapper {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        padding-top: 1rem;
        padding-bottom: 1rem;

        &.loaded {
            .row {
                .payment {
                    border: 1px solid colors.$medGray;
                }

                .invoice {
                    .totals {
                        background-color: colors.$lightGray;
                    }
                }
            }
        }

        .co-header {
            padding-left: 1rem;
            padding-right: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 1rem;

            h1 {
                font-size: 1.25rem;
            }

            h3 {
                font-size: 1.5rem;
            }
        
            .company-logo {
                margin-left: auto;
                max-width: 120px;
            }
        }

        .loading {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 45px;
        
            &:before{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transform-origin: 50% 100%;
                display: block;
                content: '';
                width: 30px;
                height: 15px;
                border-radius: 99px 99px 0 0;
                box-shadow: 0 -2px 0 0 rgba(#666, .5);
                animation: spinnyTop .75s linear 0s infinite forwards;
            }
        
            &:after{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 50%);
                transform-origin: 50% 0;
                display: block;
                content: '';
                width: 30px;
                height: 15px;
                border-radius: 0 0 99px 99px;
                box-shadow: 0 2px 0 0 rgba(#666, .5);
                animation: spinnyBottom 1.5s linear 0s infinite forwards;
            }
        }
        
        .row {
            padding-left: 1rem;
            padding-right: 1rem;
        
            .payment {
                position: relative;
                padding: 1.25rem 1rem 1rem;
                border: 1px solid #fff;
                border-radius: 0 0 globalstyles.$borderRadius globalstyles.$borderRadius;
            }
        
            .invoice {
                .totals {
                    overflow: hidden;
                    
                    li {
                        padding: 1rem;
                    }
                }
                
                .totals {
                    display: grid;
                    border-radius: globalstyles.$borderRadius globalstyles.$borderRadius 0 0;
                    background-color: #fff;
                    border-top: 1px solid colors.$medGray;
                    border-right: 1px solid colors.$medGray;
                    border-left: 1px solid colors.$medGray;
                    grid-template-columns: repeat(2, auto);
        
                    li {
                        &.amount {
                            text-align: right;
                        }
        
                        &.title {
                            font-weight: bold;
                        }
                        
                        &.total {
                            font-weight: bold;
                            font-size: 1.25rem;
                        }
                    }
                }
            }
        }

    }
}

.rw-logo-cont {
    display: flex;
    justify-content: center;
    margin-top: 4rem;

    .rw-logo {
        max-width: 134px;
        display: block;
    }
}

.tos {
    margin-bottom: 2rem;
}

@media all and (min-width: breakpoints.$sml) {
    .App {
        .wrapper {
            padding-top: 1rem;
        }
    }
}
