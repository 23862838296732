@use "vars/breakpoints";
@use "vars/colors";
@use "vars/fonts";
@use "vars/globalstyles";

.message-wrap {
    padding: 1rem;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    .message {
        background-color: #fff;
        padding: 1rem;
        border-radius: 5px;

        &.error {
            background-color: #cb1515;
    
            p {
                color: #fff;
                font-weight: 700;
            }
        }
    }


    button {
        margin-top: 1rem;
    }
}